<template>
  <div class="content-wrap" style="background: #fff">
    <div class="wrap">
      <header class="pt-32">
        <div class="logo mb-16">SQUARE+</div>
        <div class="fm-12 f-g40 mb-16">파트너 가입 신청</div>
        <div class="navBar mb-32">
          <ul>
            <li class="ml-16 fm-14 nav-selected">1.개인 정보 입력</li>
            <li class="ml-16 fm-14">2.분야 입력</li>
            <li class="ml-16 fm-14">3.가입동의</li>
            <li class="ml-16 fm-14">4.가입신청 완료</li>
          </ul>
        </div>
      </header>
      <div class="contentBox">
        <div class="title_name mb-80">
          <span class="fm-16">본인 인증</span>
          <p class="fr-12 mt-8">아래 사항을 확인 후 가입절차를 진행해주세요.</p>
        </div>
        <div
          class="mb-80 d-flex justify-content-center align-items-center column text-center"
        >
          <div
            class="exclamation-mark mb-32 d-flex justify-content-center align-items-center fb-h3"
          >
            !
          </div>
          <div class="mb-24 f-red fr-16">본인인증이 필요합니다.</div>
          <div class="mb-24 fr-14" style="color: #515466">
            실명인증으로 가입 여부를 확인하고 있습니다.<br />
            입력하신 개인정보는 가입완료 전까지 저장되지 않습니다.
          </div>
          <button-common :size="SIZE_M" :clr="CLR_G" @click="doAuth()"
            >본인인증</button-common
          >
        </div>
        <div class="fm-12 text-center explanation pt-16">
          본인 명의의 휴대전화 번호를 이용하여 본인인증을 시도해 주시기
          바랍니다.<br />
          타인 명의의 휴대전화을 이용하시거나 법인 명의의 휴대전화을 이용중인
          회원님은 휴대전화 본인인증이 불가합니다.
        </div>
      </div>
    </div>
    <div class="next mt-64 mb-80">
      <button-common :size="SIZE_S" :clr="CLR_W" @click="goBack()"
        >이전</button-common
      >
    </div>

    <form name="form_chk" method="post">
      <input type="hidden" name="m" value="checkplusService" />
      <input type="hidden" name="EncodeData" value="" />
    </form>
  </div>
</template>
<script>
export default {
  name: "Auth",
  components: {},
  data() {
    return {};
  },
  mounted() {
    if (this.$route.query) {
      let result = this.$route.query.result;
      if (result === "FL") {
        console.log("실패");
      }
    }
  },
  methods: {
    async checkAccount() {
      const popbill = require("popbill");
      popbill.config({
        LinkID: "SAILOR",
        SecretKey: "Mem/+KAspbKCnJNiu08dXjWFQUAUU64cLFMQzwBtxC0=",
        // 연동환경 설정값, 개발용(true), 상업용(false)
        IsTest: true,
        // 인증토큰 IP제한기능 사용여부, 권장(true)
        IPRestrictOnOff: true,
        defaultPopbillExceptionorHandler: function (Error) {
          console.log("Error Occur : [" + Error.code + "] " + Error.message);
        },
      });

      const easyFinBankService = popbill.EasyFinBankService();
      easyFinBankService.registBankAccount(CorpNum, BankAccountInfo, UserID, this.cbCheckAccount, this.errCheckAccount)
    },
    cbCheckAccount(result) {
      console.log(result)
    },
    errCheckAccount(err) {
      console.log(err)
    },
    async doAuth() {
      const rs = await this.$axios({
        url: "/partner/reg/start",
        params: {
          returnUrl: "http://localhost:8080/sign/email",
          errorReturnUrl: "http://localhost:8080/sign/auth",
        },
      });
      if (rs.result === "SUC") {
        document.form_chk.action =
          "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.form_chk.EncodeData.value = rs.sEncData;
        document.form_chk.submit();
      }
    },
    goNextPage() {
      this.$router.push({
        path: `/sign/email`,
        meta: { id: "0-1-4" },
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/join";

.apply-title {
  font-size: 16px;
  color: #515466;
  font-weight: 700;
}

.apply-logo-title {
  font-size: 14px;
  color: #515466 !important;
  font-weight: 700;
  margin-bottom: 4px;
}

.apply-logo-sub-title {
  font-size: 12px;
  color: #828699 !important;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--gray20);
}

.apply-join-title {
  font-size: 12px;
  color: #515466 !important;
  font-weight: 500;
}
</style>
